import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=09601cc8&scoped=true&"
import script from "./mobile.vue?vue&type=script&lang=js&"
export * from "./mobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09601cc8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/sd4-test-admin/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('09601cc8')) {
      api.createRecord('09601cc8', component.options)
    } else {
      api.reload('09601cc8', component.options)
    }
    module.hot.accept("./mobile.vue?vue&type=template&id=09601cc8&scoped=true&", function () {
      api.rerender('09601cc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/setting/featuresManagement/rolesSetting/components/setPermissions/mobile.vue"
export default component.exports