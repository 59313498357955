var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-table",
        {
          attrs: {
            stripe: "",
            border: "",
            data: _vm.list,
            "span-method": _vm.cellMerge,
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "", label: "版块", width: "160", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 0, "false-label": 1 },
                        on: {
                          change: function ($event) {
                            return _vm.handleA($event, row)
                          },
                        },
                        model: {
                          value: row.is_hide,
                          callback: function ($$v) {
                            _vm.$set(row, "is_hide", $$v)
                          },
                          expression: "row.is_hide",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.title) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: { prop: "", label: "功能列表", width: "", align: "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return _vm._l(row.sub, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          attrs: { "true-label": 0, "false-label": 1 },
                          on: {
                            change: function ($event) {
                              return _vm.handleB($event, row, row.sub)
                            },
                          },
                          model: {
                            value: item.is_hide,
                            callback: function ($$v) {
                              _vm.$set(item, "is_hide", $$v)
                            },
                            expression: "item.is_hide",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("功能列表"),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { stripe: "", data: _vm.list3 } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "",
              label: "操作权限",
              width: "160px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.group_name) + " ")]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: { prop: "", label: "功能列表", width: "", align: "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return _vm._l(row.list, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          attrs: { "true-label": 0, "false-label": 1 },
                          model: {
                            value: item.status,
                            callback: function ($$v) {
                              _vm.$set(item, "status", $$v)
                            },
                            expression: "item.status",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("功能列表"),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "10px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }