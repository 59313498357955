<template>
  <div class="orderTest-container">
    <el-table stripe border :data="list" :span-method="cellMerge">
      <el-table-column prop="" label="版块" width="160" align="center">
        <template #default="{ row }">
          <el-checkbox
            v-model="row.is_hide"
            :true-label="0"
            :false-label="1"
            @change="handleA($event, row)"
          >
            {{ row.title }}
          </el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="" label="功能列表" width="" align="left">
        <template slot="header">
          <div style="text-align: center">功能列表</div>
        </template>
        <template #default="{ row }">
          <el-checkbox
            v-for="(item, index) in row.sub"
            :key="index"
            v-model="item.is_hide"
            :true-label="0"
            :false-label="1"
            @change="handleB($event, row, row.sub)"
          >
            {{ item.title }}
          </el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <el-table stripe :data="list3">
      <el-table-column prop="" label="操作权限" width="160px" align="center">
        <template #default="{ row }">
          {{ row.group_name }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="功能列表" width="" align="left">
        <template slot="header">
          <div style="text-align: center">功能列表</div>
        </template>
        <template #default="{ row }">
          <el-checkbox
            v-for="(item, index) in row.list"
            :key="index"
            v-model="item.status"
            :true-label="0"
            :false-label="1"
          >
            {{ item.title }}
          </el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 10px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保存</el-button>

      <el-button @click="close">取 消</el-button>
    </div>
  </div>
</template>
<script>
  import { mobileSubmit, mobileSubmit2 } from '@/api/role'
  export default {
    name: 'Mobile',
    components: {},
    props: ['list', 'list3', 'id'],
    data() {
      return {
        spanArr: [],
      }
    },
    computed: {},
    watch: {},
    created() {
      console.log('mobile', this.list2, this.id)
      this.getSpanArr(this.list)
    },
    mounted() {},
    methods: {
      // 处理合并表格数据
      getSpanArr(data) {
        for (let i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
          } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i].title === data[i - 1].title) {
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
            }
          }
        }
      },
      // 合并列
      cellMerge({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          let _row = this.spanArr[rowIndex]
          let _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      handleA(val, row) {
        console.log(val, row)
        row.sub.forEach((item) => {
          item.is_hide = val
        })
      },
      handleB(val, row, ele) {
        let l = ele.length
        let i = 0
        ele.forEach((item) => {
          if (item.is_hide == 0) {
            i++
          }
        })
        if (i == l) {
          row.is_hide = 0
        } else {
          row.is_hide = 1
        }
        console.log(i)
        console.log(val, row, ele)
      },
      handleSave() {
        let end = []
        this.list.forEach((item) => {
          if (item.is_hide == 0) {
            end.push(item.id)
          }
          if (item.sub) {
            item.sub.forEach((item2) => {
              if (item2.is_hide == 0) {
                end.push(item2.id)
              }
            })
          }
        })
        console.log(end, this.id)
        end = JSON.stringify(end) == '[]' ? '' : JSON.stringify(end)

        let end2 = []
        this.list3.forEach((item) => {
          if (item.list.length > 0) {
            item.list.forEach((item2) => {
              if (item2.status == 0) {
                end2.push(item2.fun_id)
              }
            })
          }
        })
        end2 = JSON.stringify(end2) == '[]' ? '' : JSON.stringify(end2)
        mobileSubmit({ id: this.id, auth_data: end }).then((res) => {
          // this.$message.success(res.msg)
          mobileSubmit2({ id: this.id, auth_data: end2 }).then((res2) => {
            this.$emit('close')

            this.$message.success(res2.msg)
          })
        })
      },
      close() {
        this.$emit('close')
      },
    },
  }
</script>
<style lang="scss" scoped></style>
