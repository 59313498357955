var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.showcoupon,
            title: _vm.title,
            center: "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showcoupon = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "150px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名称：", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请输入角色名称" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "初始权限：", prop: "parent_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: _vm.info != "1" },
                          model: {
                            value: _vm.form.parent_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "parent_id", $$v)
                            },
                            expression: "form.parent_id",
                          },
                        },
                        _vm._l(_vm.parent, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.title, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：", prop: "desc" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          placeholder: "请输入备注",
                          type: "textarea",
                          maxlength: "300",
                        },
                        model: {
                          value: _vm.form.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "desc", $$v)
                          },
                          expression: "form.desc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("正常"),
                          ]),
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("停止"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.info == 1,
                      expression: "info == 1",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlersave },
                },
                [_vm._v(" 保 存 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.info == 2,
                      expression: "info == 2",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerinfo },
                },
                [_vm._v(" 保 存 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showcoupon = !_vm.showcoupon
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }