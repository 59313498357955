var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog
    ? _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "权限设置",
            visible: _vm.showDialog,
            width: "1200px",
            top: "5vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "orderTest-container",
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  model: {
                    value: _vm.nav,
                    callback: function ($$v) {
                      _vm.nav = $$v
                    },
                    expression: "nav",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("电脑端"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("手机端"),
                  ]),
                ],
                1
              ),
              _vm.show
                ? _c("div", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.nav == "1",
                            expression: "nav == '1'",
                          },
                        ],
                      },
                      [
                        _c("pc", {
                          ref: "pc",
                          attrs: {
                            id: _vm.id,
                            list: _vm.list,
                            "list-new": _vm.listNew,
                          },
                          on: { close: _vm.close },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.nav == "2",
                            expression: "nav == '2'",
                          },
                        ],
                      },
                      [
                        _c("mobile", {
                          ref: "mobile",
                          attrs: {
                            id: _vm.id,
                            list: _vm.list2,
                            list3: _vm.list3,
                          },
                          on: { close: _vm.close },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }