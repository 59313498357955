<template>
  <div class="orderTest-container">
    <el-row :gutter="20">
      <el-col :span="14">
        <el-table stripe border :data="list" :span-method="cellMerge">
          <el-table-column prop="" label="版块" width="130" align="center">
            <template #default="{ row }">
              <el-checkbox
                v-model="row.checked_1"
                @change="handleA($event, row)"
              >
                {{ row.title_1 }}
              </el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="功能节点" width="" align="left">
            <template slot="header">
              <div style="text-align: center">功能节点</div>
            </template>
            <template #default="{ row }">
              <el-checkbox
                v-model="row.checked"
                style="margin-left: 10px"
                @change="handleB($event, row)"
              >
                {{ row.title }}
              </el-checkbox>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="" label="操作权限" width="" align="left">
            <template slot="header">
              <div style="text-align: center">操作权限</div>
            </template>
            <template #default="{ row }">
              <el-checkbox
                v-for="(item, index) in row._sub_"
                :key="index"
                v-model="item.checked"
                @change="handleC($event, row, index)"
              >
                {{ item.title }}
              </el-checkbox>
            </template>
          </el-table-column> -->
        </el-table>
      </el-col>
      <el-col :span="8">
        <el-table stripe border :data="listNew">
          <el-table-column prop="title" label="权限操作" width="" align="left">
            <template slot="header">
              <div style="text-align: center">权限操作</div>
            </template>
            <template #default="{ row }">
              <el-checkbox v-model="row.is_check" style="margin-left: 10px">
                {{ row.title }}
              </el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 10px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保存</el-button>

      <el-button @click="close">取 消</el-button>
    </div>
  </div>
</template>
<script>
  import { pcSubmit } from '@/api/role'
  export default {
    name: 'Pc',
    components: {},
    props: ['list', 'id', 'listNew'],
    data() {
      return {
        spanArr: [],
      }
    },
    computed: {},
    watch: {},
    created() {
      console.log('pc')
      this.getSpanArr(this.list)
    },
    mounted() {},
    methods: {
      // 处理合并表格数据
      getSpanArr(data) {
        for (let i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i].title_1 === data[i - 1].title_1) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      // 合并列
      cellMerge({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          let _row = this.spanArr[rowIndex]
          let _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      handleA(val, row) {
        console.log(row)
        let name = row.title_1
        this.list.forEach((item) => {
          if (item.title_1 == name) {
            if (val) {
              item.checked = val
              if (row._sub_) {
                item._sub_.forEach((item2) => {
                  item2.checked = val
                })
              }
            } else {
              item.checked = val
              if (row._sub_) {
                item._sub_.forEach((item2) => {
                  item2.checked = val
                })
              }
            }
          }
        })
      },
      handleB(val, row) {
        console.log(row)
        let fatherNode = row.node_1
        let i = 0
        let activeArr = this.list.filter((item) => item.node_1 == fatherNode)
        let bSum = activeArr.length
        row._sub_.forEach((item) => {
          item.checked = val
        })
        activeArr.forEach((item) => {
          if (item.checked) {
            i++
          }
        })
        if (i == bSum) {
          activeArr[0].checked_1 = true
        } else {
          activeArr[0].checked_1 = false
        }
        console.log('fatherNode', fatherNode)
        console.log('bSum', bSum)
        console.log('i', i)
        // if (val) {
        // } else {
        // }
      },
      handleC(val, row, index) {
        let fatherNode = row.node_1
        let activeArr = this.list.filter((item) => item.node_1 == fatherNode)
        console.log(row)
        console.log(activeArr)
        console.log(row._sub_, index)
        let i = 0
        let l = row._sub_.length
        row._sub_.forEach((item) => {
          if (item.checked) {
            i++
          }
        })
        console.log(i)
        if (i == l) {
          let z = 0
          row.checked = true
          activeArr.forEach((item2) => {
            if (item2.checked) {
              z++
            }
          })
          if (z == activeArr.length) {
            activeArr[0].checked_1 = true
          }
        } else {
          row.checked = false
          // row
          activeArr[0].checked_1 = false
        }
      },
      handleSave() {
        let end = []
        this.list.forEach((item) => {
          console.log(item)
          if (item.checked_1 == true) {
            end.push(item.pnode)
          }
          if (item.checked) {
            end.push(item.node)
          }
          if (item._sub_) {
            item._sub_.forEach((item2) => {
              if (item2.checked) {
                end.push(item2.node)
              }
            })
          }
        })
        var fun_data = this.listNew.map((list) => {
          if (list.is_check) {
            list.is_check = 1
          } else {
            list.is_check = 0
          }
          console.log(list)
          return list
        })
        console.log(fun_data, this.listNew)
        // end = [...new Set(end)]
        // console.log(end)
        fun_data =
          JSON.stringify(fun_data) == '[]' ? '' : JSON.stringify(fun_data)
        end = JSON.stringify(end) == '[]' ? '' : JSON.stringify(end)

        pcSubmit({ id: this.id, auth_data: end, fun_data: fun_data }).then(
          (res) => {
            this.$message.success(res.msg)
            this.$emit('close')
          }
        )
      },
      close() {
        this.$emit('close')
      },
    },
  }
</script>
<style lang="scss" scoped></style>
