import request from '@/utils/request'

// 后台权限 列表
export function getPCdata(data) {
  return request({
    url: '/setAdmin/role/auth-list-admin',
    method: 'post',
    data,
  })
}

// 后台权限 提交
export function pcSubmit(data) {
  return request({
    url: '/setAdmin/role/auth-set-admin',
    method: 'post',
    data,
  })
}

// 角色 权限 手机端列表
export function getMobileData(data) {
  return request({
    url: '/setAdmin/role/auth-list',
    method: 'post',
    data,
  })
}

// 角色 权限 手机端设置
export function mobileSubmit(data) {
  return request({
    url: '/setAdmin/role/auth-set',
    method: 'post',
    data,
  })
}

// 角色 权限 手机端列功能表
export function getMobileData2(data) {
  return request({
    url: '/setAdmin/role/auth-fun-list',
    method: 'post',
    data,
  })
}

// 权限 手机端功能设置
export function mobileSubmit2(data) {
  return request({
    url: '/setAdmin/role/auth-fun-set',
    method: 'post',
    data,
  })
}
