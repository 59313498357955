<template>
  <el-dialog
    v-if="showDialog"
    :modal="false"
    title="权限设置"
    :visible.sync="showDialog"
    width="1200px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <el-radio-group v-model="nav" style="margin-bottom: 10px">
        <el-radio-button label="1">电脑端</el-radio-button>
        <el-radio-button label="2">手机端</el-radio-button>
      </el-radio-group>
      <div v-if="show">
        <div v-show="nav == '1'">
          <pc
            :id="id"
            ref="pc"
            :list="list"
            :list-new="listNew"
            @close="close"
          ></pc>
        </div>
        <div v-show="nav == '2'">
          <mobile
            :id="id"
            ref="mobile"
            :list="list2"
            :list3="list3"
            @close="close"
          ></mobile>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { getPCdata, getMobileData, getMobileData2 } from '@/api/role'
  import Pc from './pc'
  import Mobile from './mobile'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'SetPermissions',
    components: { Pc, Mobile },
    data() {
      return {
        id: 0,
        nav: '1',
        showDialog: false,
        list: [],
        list2: [],
        list3: [],
        listNew: [],
        show: false,
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData().then(() => {
            this.fetchData2().then(() => {
              this.fetchData22()
            })
          })
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        this.show = false
        let a = []
        let { data } = await getPCdata({ id: this.id })
        this.list = data
        this.list.forEach((item) => {
          item.forEach((item2) => {
            a.push(item2)
          })
        })
        console.log(a)
        this.list = a
        await postAction('setAdmin/role/auth-list-admin-new', {
          id: this.id,
        }).then((res) => {
          console.log(res)
          this.listNew = res.data.fun_data.map((list) => {
            if (list.is_check) {
              list.is_check = true
            } else {
              list.is_check = false
            }
            return list
          })
        })
      },
      async fetchData2() {
        let { data } = await getMobileData({ id: this.id })
        console.log('m', data)
        this.list2 = data
      },
      async fetchData22() {
        let { data } = await getMobileData2({ id: this.id })
        console.log('m2', data)
        this.list3 = data
        this.show = true
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
