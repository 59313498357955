var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    border: "",
                    data: _vm.list,
                    "span-method": _vm.cellMerge,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "版块",
                      width: "130",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-checkbox",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.handleA($event, row)
                                  },
                                },
                                model: {
                                  value: row.checked_1,
                                  callback: function ($$v) {
                                    _vm.$set(row, "checked_1", $$v)
                                  },
                                  expression: "row.checked_1",
                                },
                              },
                              [_vm._v(" " + _vm._s(row.title_1) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "title",
                        label: "功能节点",
                        width: "",
                        align: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleB($event, row)
                                    },
                                  },
                                  model: {
                                    value: row.checked,
                                    callback: function ($$v) {
                                      _vm.$set(row, "checked", $$v)
                                    },
                                    expression: "row.checked",
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.title) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("功能节点"),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-table",
                { attrs: { stripe: "", border: "", data: _vm.listNew } },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "title",
                        label: "权限操作",
                        width: "",
                        align: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  model: {
                                    value: row.is_check,
                                    callback: function ($$v) {
                                      _vm.$set(row, "is_check", $$v)
                                    },
                                    expression: "row.is_check",
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.title) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("权限操作"),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "10px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }