<!--
 * @Author: your name
 * @Date: 2021-01-29 14:34:38
 * @LastEditTime: 2021-01-29 16:24:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\rolesSetting\components\AddCharacter.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="showcoupon"
      :title="title"
      center
      width="600px"
    >
      <div>
        <el-form ref="form" :model="form" label-width="150px">
          <el-form-item label="角色名称：" prop="title">
            <el-input
              v-model="form.title"
              placeholder="请输入角色名称"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="初始权限：" prop="parent_id">
            <el-select v-model="form.parent_id" :disabled="info != '1'">
              <el-option
                v-for="list in parent"
                :key="list.id"
                :label="list.title"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" prop="desc">
            <el-input
              v-model="form.desc"
              placeholder="请输入备注"
              type="textarea"
              maxlength="300"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio label="1">正常</el-radio>
              <el-radio label="0">停止</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-show="info == 1" type="primary" @click="handlersave">
          保 存
        </el-button>
        <el-button v-show="info == 2" type="primary" @click="handlerinfo">
          保 存
        </el-button>
        <el-button @click="showcoupon = !showcoupon">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        showcoupon: false,
        title: '新建角色',
        parent: [],
        form: {
          title: '', //名称
          desc: '', //描述
          status: '1', //状态
          parent_id: '', //父系统ID
        },
        url: {
          syslist: '/setAdmin/role/syslist',
          save: '/setAdmin/role/add',
          info: '/setAdmin/role/update',
        },
        info: 1,
      }
    },
    watch: {
      showcoupon(val) {
        if (!val) {
          this.title = '新建角色'
          this.$refs['form'].resetFields()
          this.info = 1
          delete this.form.id
          this.form.title = ''
          this.form.desc = ''
          this.form.status = '1'
          this.form.parent_id = ''
        }
      },
    },
    mounted() {
      this.handlersyslist()
    },
    methods: {
      handlerData(row) {
        this.info = 2
        this.form.title = row.title
        this.form.desc = row.desc
        this.form.status = String(row.status)
        this.form.parent_id = String(row.parent_id)
        this.$set(this.form, 'id', row.id)
        this.$forceUpdate()
      },
      handlersave() {
        postAction(this.url.save, this.form).then((res) => {
          console.log(res.data)
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.showcoupon = false
          this.$emit('getlist')
        })
      },
      handlerinfo() {
        var a = JSON.parse(JSON.stringify(this.form))
        delete a.parent_id
        postAction(this.url.info, a).then((res) => {
          console.log(res.data)
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.showcoupon = false
          this.$emit('getlist')
        })
      },
      handlersyslist() {
        postAction(this.url.syslist, {})
          .then((res) => {
            console.log(res)
            this.parent = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
